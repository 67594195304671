<template>
    <highmaps class="chart h-full w-full" :options="options" ref="spainMap" :id="chartId"></highmaps>        
</template>

<script>

    import mapData from '../../../../../world-map.js'

    export default {
        props: ['data','chartId'],
        computed: {
            options() {
                return {
                    chart: {
                        backgroundColor: null,
                    },
                    //colors: ['$gray', '$gray'],
                    title: {
                        text: ''
                    },
                    legend: {
                        enabled: false,
                        align: 'left',
                        verticalAlign: 'top',
                        layout: 'vertical',
                        y: 100,
                        itemMarginTop: 4,
                        itemMarginBottom: 4,
                        itemStyle: {
                            color: '#637079',
                            fontWeight: 'normal',
                            fontSize: '15px',
                            opacity: '1'
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    subtitle: {
                        text: ''
                    },
                    mapNavigation: {
                        enabled: false,
                        buttonOptions: {
                            verticalAlign: 'bottom'
                        }
                    },
                    tooltip: {
                        backgroundColor: "rgba(18, 24, 34, 1)",
                        borderWidth: 0,
                        borderRadius: 20,
                        shadow: false,
                        useHTML: true,
                        outside: false,
                        formatter:  function() {
                            console.log(this);
                            return `<div class="mapTooltip"><p class="mapCommunity">${this.key}</p><p class="mapValue">${this.point.value}</p></div>`
                        },
                    },
                    colorAxis: {
                        min: null,
                        // max: 1000,
                        type: 'logarithmic',
                        minColor: '#004e61',
                        maxColor: '#61cce6',
                    },
                    series: [{
                        id: 'map-world',
                        name: 'World',
                        mapData:  mapData,
                        //joinBy: ['hc-key','code'],
                        //keys: ['code', 'value'],
                        data: this.data,
                        nullColor:'#293345',
                        borderColor:'#121822',
                        borderWidth: .2,
                    }]
                }
            }
        },
    };
</script>
<style>
    .chart{
        height: 100% !important;
        width: 100% !important;
    }
    .highcharts-container{
        height: 100% !important;
        width: 100% !important;
    }
    .mapValue {
        color: #79849A;
        font-size: 12.5px;
    }
    .mapCommunity {
        color: #4E82C4;
        font-size: 14.5px;
    }

</style>
