<template>
    <div>
        <Rotate />
        <transition name="fade" mode="out-in" class="z-50">
        <div class="w-screen md:w-3/5 max-w-mobile lg:max-w-tablet h-full min-h-screen mx-auto bg-body flex flex-col no-mobile-shadow">
            <Header isMap @changeMap="changeMap">
                <template #menuIcon>
                    <div @click="backToMainDashboard" class="bg-main rounded-2xl w-12 h-12 flex-center text-white">
                        <i class="mdi mdi-chevron-left text-5xl"></i>
                    </div>
                </template>
            </Header>
            <Headline isFilter>
                <template slot="headlineTitle">
                    <span class="text-3xl">edem.eu</span>
                </template>
                <template slot="headlineSubtitle">
                    {{selected.length > 0 ? selected : 'Todas las áreas'}} <span class="text-secondary ml-1">|</span> <i class="mdi mdi-calendar-arrow-left mr-2"></i>30 días
                </template>
                <template slot="headlineAction">
                    <span @click="openFilter">Filtrar</span>
                </template>
            </Headline>    
            <div class="h-64 md:h-72 lg:h-80 px-4 py-2">
                <TrafficWorldMap :data="countriesMapData" v-if="isWorld" />
                <TrafficSpainMap :data="regionsMapData" v-else />
            </div>

            <All model="Area" :immediate="true" ref="areasRef" @success="setAreas" />

            <All model="GaCountriesArea" :immediate="true" ref="getCountriesRef" :query="gaTrafficFilter" :data.sync="countries"/>
            <All model="GaRegionsArea" :immediate="true" ref="getRegionsRef" :query="gaTrafficFilter" :data.sync="regions"/>

            <ItemsList v-if="countries && regions" class="-mt-10" :isWorld="isWorld" :data="{countries:countries,communities:regions}" />
            
                <portal v-if="filter" to="modals">
                    <transition name="fade" mode="out-in" appear>
                        <div class="absolute inset-0 w-screen h-screen bg-body bg-opacity-75 bg-blur z-50"></div>
                    </transition>
                    <transition name="slide-fade" mode="out-in" appear>
                        <Modal @setSelected="setSelected" @openFilter="openFilter" :selected="gaAreaFilter" :options="options">
                            <template slot="modalTitle">
                                <span class="text-3xl">Áreas</span>
                            </template>
                            <template slot="modalSubtitle">
                                Seleccionar un área
                            </template>
                        </Modal>
                    </transition>
                </portal>
        </div>
        </transition>
    </div>
</template>

<script>
import { actions } from '@/store';
import Header from "@/pages/inside/sections/dashboards/Header"
import Rotate from "@/pages/inside/sections/dashboards/Rotate"
import Headline from "@/pages/inside/sections/dashboards/Headline"
import Modal from "@/pages/inside/sections/dashboards/Modal"
import ItemsList from "@/pages/inside/sections/dashboards/traffic/ItemsList"
import TrafficSpainMap from "@/pages/inside/sections/dashboards/traffic/TrafficSpainMap"
import TrafficWorldMap from "@/pages/inside/sections/dashboards/traffic/TrafficWorldMap"
import All from "../../../../api/components/All";

export default {
    name: 'DashboardTraffic',
    components: {
        All,
        Header,
        Rotate,
        Headline,
        Modal,
        ItemsList,
        TrafficSpainMap,
        TrafficWorldMap,
    },
    data() {
        return {
            gaTrafficFilter: {
                id: null,
                from: this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
                to:this.$dayjs().format('YYYY-MM-DD')
            },
            gaAreaFilter: {
                id: null
            },
            filter: false,
            isWorld: false,
            options: [],
            countries: null,
            selected: "",
            regions: null,
            countriesMapData: [],
            regionsMapData: [],
        }
    },
    computed: {
        me() {
            return actions.getUser();
        },
    },
    methods: {
        openFilter() {
            this.filter = !this.filter;
        },
        changeMap() {
            this.isWorld = !this.isWorld;
        },
        backToMainDashboard() {
            this.$router.push('/executive-summary');
        },
        setSelected(selected) {
            if(this.gaAreaFilter.id === selected.id){
                this.gaTrafficFilter.id = null;
                this.gaAreaFilter.id = null;
                this.selected = "";
                return;
            }
            this.gaTrafficFilter.id = selected.id;
            this.gaAreaFilter.id = selected.id;
            this.selected = selected.name
        },
        getCourseId(){
            return false;
        },
        setAreas(areas){
            this.options = areas.data;
        },
    },
    watch:{
        countries(n){
            for (let i = 0; i < n.data.length; i++){
                if (n.data[i].visits !== 0) {
                    this.countriesMapData.push([
                        n.data[i].code.toLowerCase(),
                        parseInt(n.data[i].visits)
                    ]);
                }
            }
        },
        regions(n){
            for (let i = 0; i < n.data.length; i++){
                if (n.data[i].visits !== 0) {
                    this.regionsMapData.push([
                        n.data[i].code.toLowerCase(),
                        parseInt(n.data[i].visits)
                    ]);
                }
            }
        },
        selected(){
            this.$refs.getCountriesRef.request();
            this.$refs.getRegionsRef.request();
        },
        gaAreaFilter(n){
            this.gaTrafficFilter.id = n.id;
        }
    }
}
</script>

<style scoped>
</style>