<template>
   <div class="w-full flex flex-col flex-1 min-h-0 px-4 py-2 h-72">

        <div class="w-full rounded-lg border-2 border-main flex justify-between items-center px-4 py-3">
            <div class="text-white font-medium">{{isWorld ? 'Mundial' : 'España'}}</div>
            <div class="text-white font-medium">{{isWorld ? worldTotal : spainTotal | numberFormat}}</div>
        </div>
        <div class="text-sm text-secondary text-left mt-3 mb-2">{{isWorld ? 'Por país' :  'Por comunidad'}}</div>
        <div v-if="isWorld" class="overflow-auto flex-1 min-h-0">
            <div v-for="(country, index) in countries" :key="index" class="w-full rounded-lg bg-box flex justify-between items-center px-4 py-3 mb-2">
                <div class="text-secondary font-medium text-left leading-none">{{country.name}}</div>
                <div class="text-white font-medium">{{country.visits | numberFormat}}</div>
            </div>
        </div>
        <div v-else class="overflow-auto flex-1 min-h-0">
            <div v-for="(community, index) in communities" :key="index" class="w-full rounded-lg bg-box flex justify-between items-center px-4 py-3 mb-2">
                <div class="text-secondary font-medium text-left leading-none">{{community.name}}</div>
                <div class="text-white font-medium">{{community.visits | numberFormat}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ItemsList',
        props: {
            isWorld: {
                type: Boolean,
                required: true
            },
            data: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                communities: [],
                countries: []
            }
        },
        computed: {
            spainTotal() {
                for(let i = 0; i < this.countries.length; i++){
                    if(this.countries[i].code == 'ES'){
                        return parseInt(this.countries[i].visits);
                    }
                }

                return 0;
            },
            worldTotal(){
                let worldTotal = 0;

                for(let i = 0; i < this.countries.length; i++){
                    worldTotal += parseInt(this.countries[i].visits);
                }

                return worldTotal;
            }
        },
        watch: {
            data(n) {
                if(n){
                    if(n.communities){
                        this.communities = n.communities.data;
                    }
                    if(n.countries){
                        this.countries = n.countries.data;
                    }
                }
            }
        },
        mounted() {
            if(this.data){
                if(this.data.communities){
                    this.communities = this.data.communities.data;
                }
                if(this.data.countries){
                    this.countries = this.data.countries.data;
                }
            }
        }
    }
</script>